import React from "react";
import './Thankyou.css';
import { useEffect} from 'react';
import { Container, Row, Col, Button} from 'react-bootstrap';

const Thankyou = () => {
    useEffect(() => {
        document.title = 'Thank You | Quadrics';
      });
    return(
        <div>
            <div className="home-banner">
                <Container>
                    <Row className="justify-content-center">
                        <Col className="home-banner-copy" xl={5} lg={6}>
                            <h1 className="mb-0 w-100 text-center">Thank You</h1>
                        </Col>
                        <Col className="d-none d-lg-block" xl={7} lg={6}>
                            <lottie-player src="https://lottie.host/f43d00c9-96b2-46d9-8163-899280480634/nVkRQJEt79.json" background="transparent"  speed="1"  loop autoplay></lottie-player>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
export default Thankyou;