import React from "react";
import './Contact.css';
import { Container, Row, Col, Button} from "react-bootstrap";
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLongArrowRight} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import WOW from 'wowjs';
import Axios from 'axios';

const Contact = () => {

    useEffect(() => {
        document.title = 'Contact Us | Quadrics';
      });
    let navigate = useNavigate();
    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

      const url = 'https://14aq6wo6tk.execute-api.ap-southeast-1.amazonaws.com/dev/quadrics-contact/contacts';

      //Initial Input Value
      const initialValues = {
        email: '',
        tnc: false,
        purpose: ''
      };
    
      //Validation
      const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.email) {
          errors.email = 'Email is required';
        } else if (!regex.test(values.email)) {
          errors.email = 'Invalid Email';
        }
        return errors;
      };
    
      function submitForm(values) {
        /* e.preventDefault(); */
        console.log('submit');
         Axios.post(url, {
           email: values.email,
           purpose: values.purpose,
           tnc: values.tnc,
           newsletter: values.newsletter,
         }).then((res) => {
           console.log(res.data);
           return navigate('/thankyou');
         });
      }
    return (  
        <div>
            <video autoPlay loop muted className="video-header">
                <source src={'Assets/contact-header-video.mp4'} type="video/mp4"/>
            </video>
            <div className="video-overlay"></div>
            <section className="background-black contact-header">
                <Container>
                    <h1 className="big wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">let's</h1>
                    <h1 className="big ml-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay=".2s">talk</h1>
                    <p className=" wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">Let's stay in touch! Complete the form below, and we'll be in touch promptly. Alternatively, you can reach out to us directly by email. Looking forward to hearing from you!</p>
                    <Row className="justify-content-center">
                        <Col sm={12} md={10}>
                            <Button href="hello@quadrics.co" className="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                                hello@quadrics.co
                                <FontAwesomeIcon icon={faLongArrowRight} className="long-arrow"></FontAwesomeIcon>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="background-black">
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12} md={10}>
                        <Formik initialValues={initialValues} validate={validate} onSubmit={submitForm}>
                            {(formik) => {
                            const { values, handleChange, handleSubmit, errors, touched, handleBlur, isValid, dirty, isSubmitting } = formik;
                            return (
                                <Form className="enquiry-form" onSubmit={handleSubmit}>
                                <div>
                                    <Field name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} className={errors.email && touched.email ? 'input-error'+ ' form-control' : null+ ' form-control'} type="email" placeholder="Your email address" required />
                                    <ErrorMessage name="email" component="span" className="error contact_error" />
                                </div>

                                <div role="group" aria-labelledby="my-radio-group" className="radio_group">
                                    <p className="my-3">What would you like to discuss with us?</p>
                                    <label className="form-check-label">
                                        <Field type="radio" name="purpose" value="I am looking for a job" />I am looking for a job
                                    </label>
                                    <label className="form-check-label">
                                        <Field type="radio" name="purpose" value="I want to discuss a potention project" />I want to discuss a potention project
                                    </label>
                                    <label className="form-check-label">
                                        <Field type="radio" name="purpose" value="I am a partner/ vendor" />I am a partner/ vendor
                                    </label>
                                </div>

                                <div>
                                    <label className="text-white form-check-label">
                                    <Field type="checkbox" name="newsletter" className="mr-2"
                                    />Ever wonder where people find the best news, GIFs, and trends from across the Internet?
                                    Tick this box to receive our secret recipe.
                                    </label>
                                    <ErrorMessage name="newsletter" component="div" className="text-danger"/>
                                </div>

                                <div>
                                    <label className="text-white form-check-label">
                                    <Field type="checkbox" name="tnc" className="mr-2"/>
                                    By checking the box, you agree that Quadrics may, in accordance with our privacy policy,
                                    collect and use your personal data entered in this form to provide you with our newsletters.
                                    </label>
                                    <ErrorMessage name="tnc" component="div" className="text-danger"/>
                                </div>

                                <div className="progress2-bar">
                                    <span className="bar">
                                    <span className="progress2"></span>
                                    </span>
                                </div>
                                <Button type="submit" className={dirty && isValid ? '' : 'disabled-btn'} disabled={!(dirty && isValid) || isSubmitting}>
                                    Submit
                                    <FontAwesomeIcon icon={faLongArrowRight} className="long-arrow"></FontAwesomeIcon>
                                </Button>
                                </Form>
                            );
                            }}
                        </Formik>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
}
 
export default Contact;