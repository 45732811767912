import './App.css';
import {Route, Routes, BrowserRouter} from "react-router-dom";
import { Home, About, Work, Packages, Contact} from './components';
import MainLayout from './layouts/main';
import SingleWork from './components/Work/SingleWork';
import Thankyou from './components/Thankyou/Thankyou';
import { Single } from './components';

const App = () => (
  
  <div className="App">
      <Routes>
        <Route path="/" element={<MainLayout><Home/></MainLayout>}/>
        <Route path="home" element={ <MainLayout><Home/></MainLayout>}/>
        <Route path="about" element={ <MainLayout><About/></MainLayout>}/>
        <Route path="work" element={ <MainLayout><Work/></MainLayout>}/>
        <Route path="packages" element={ <MainLayout><Packages/></MainLayout>}/>
        <Route path="contact" element={ <MainLayout><Contact/></MainLayout>}/>
        <Route path="single/:id" element={<MainLayout><Single/></MainLayout>} />
        <Route path="client/:index" element={ <MainLayout><SingleWork/></MainLayout>}/>
        <Route path="thankyou" element={ <MainLayout><Thankyou/></MainLayout>}/>
      </Routes>
  </div>
);
 
export default App;

