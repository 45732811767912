import React from "react";
import './About.css';
import { Container, Row, Col} from 'react-bootstrap';
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import { useEffect } from "react";
import WOW from 'wowjs';
// import { Helmet } from 'react-helmet-async';

const About = () => {
    useEffect(() => {
        document.title = 'Who We Are | Quadrics';
      });
      
    const options = {
        margin: 30,
        responsiveClass: true,
        autoplay: false,
        navText: ['<i class="fa fa-arrow-left"></i>','<i class="fa fa-arrow-right"></i>'],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
                nav: true,
                autoHeight: true,
            },
            768: {
                items: 2,
                margin: 30,
                nav: true,
                autoHeight: false,
                
            },
            1280: {
                items: 3,
                margin: 0,
                nav: false,
            }
        },
      };

      useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

    return (  
        <div>
        <div style={styles.Content} className="black-copy-area about">
        <lottie-player src="https://lottie.host/9a4fca5d-7153-447d-aa28-414ee4bcdc6f/QEPpzh87Gq.json" background="transparent"  speed="1"  loop autoplay></lottie-player>
            <Container>
                <Row>
                    <Col sm={12} md={6}>
                        <h2 className="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">QUADRICS:<br></br>The Creative Curiousity Quad.</h2>
                    </Col>
                    <Col sm={12} md={6}></Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}></Col>
                    <Col sm={12} md={6}>
                        <p className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">Quadrics: where 'Solution' fuels our passion, and the 'Q' is the beacon of our Curiosity. As a Creative Quad, we're not just creators, 
                        fueled by curiosity, committed to transforming your vision into reality. 
                        </p>
                        <p className="wow fadeInUp mb-0" data-wow-duration="1s" data-wow-delay="1s">With Quadrics, you're not just getting a service; you're entering a world where innovative thinking and collaborative spirit converge, making us your partner in forging a brighter tomorrow.</p>
                    </Col>
                </Row>
            </Container>
        </div>
        <section className="gray-copy-area background-smoke">
            <Container>
                <div className="top-content pb-md-5 pb-3">
                    <p className="tag wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">You're here because...</p>
                    <h2 className="py-3 wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">Hello! If you're a business owner, marketer, or have a unique idea, we're an energetic creative team, eager to explore your vision and share in the excitement.</h2>
                </div>
                <Row>
                    <Col lg={6} sm={12} className="mb-5 mb-lg-0 wow fadeInUp" data-wow-duration="1s" data-wow-delay="2s">
                        <p className="tag">Strategy</p>
                        <h3 className="py-3">Brand Strategy & Art Direction</h3>
                        <div className="content-copy">
                            <p>We're not just about answers; we're about sparking transformation. Our brand strategy and art direction define you, inspiring change and purpose in the world. Discover the difference that shapes your tomorrow.
                            </p>
                        </div>
                    </Col>
                    <Col lg={6} sm={12} className="mb-5 mb-lg-0 wow fadeInUp" data-wow-duration="1s" data-wow-delay="2s">
                        <p className="tag">Design</p>
                        <h3 className="py-3">Brand Loyalty & Beyond</h3>
                        <div className="content-copy">
                            <p>The finishing touch to a successful campaign is creative. Our team enhances brand loyalty, delivering trend-setting, on-message designs that not only raise brand and campaign awareness but also boost audience engagement and ROI.</p>
                        </div>
                    </Col>
                    <Col lg={6} sm={12} className="mb-5 mb-lg-0 wow fadeInUp" data-wow-duration="1s" data-wow-delay="2.5s">
                        <p className="tag">Lead Generation</p>
                        <h3 className="py-3">Digital Marketing</h3>
                        <div className="content-copy">
                            <p>We fuse creativity with data wizardry. Forge powerful brand connections that steer business success. Our tailor-made, scalable marketing programs ensure a constant flow through your customer journey.</p>
                        </div>
                    </Col>
                    <Col lg={6} sm={12} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="2.5s">
                        <p className="tag">Campaigns</p>
                        <h3 className="py-3">Marketing Campaigns & Automation</h3>
                        <div className="content-copy">
                        Impactful campaigns, made easy. From website content to email campaigns, we deliver real results. Automation? We've got you covered - just plug, play, and watch the waves roll in. We can be your partners in making waves.
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="process-area background-white">
            <Container>
                <h2 className="text-center wow fadeInDown" data-wow-duration="1s" data-wow-delay="2.6s">Our creative Unlocking Creativity<br/>process explained</h2>
                <p className="text-center pb-5 wow fadeInUp"  data-wow-duration="1s" data-wow-delay="2.6s">Our 3-Step Process Simplified!</p>
            </Container> 
            <div className="container-fluid">
            <OwlCarousel  
                className="process-slider"   
                {...options}>  
                <div className="item card">
                    <div className="item-inner-wrapper">
                        <div className="card-copy">
                            <p className="tag">Step 1.</p>
                            <h3 className="py-3">Clients briefs our team about the project scope & goals.</h3>
                            <p>Clients like yourself approach our team with an initial project scope they have in mind. Our creative director schedules
                                a call or a Zoom meeting to discuss your desired deliverables & budgets along with what you feel is an appropriate
                                delivery timeline.
                            </p>
                        </div>
                        <img src={'Assets/landing-page/work-red.png'} className="img-fluid big" alt="work-image1"/>
                    </div>
                </div>
                <div className="item card">
                    <div className="item-inner-wrapper">
                    <div className="card-copy">
                        <p className="tag">Step 2.</p>
                        <h3 className="py-3">Agency response.</h3>
                        <p>When you feel we're a good fit after the initial meeting, you'll receive a legally binding project document that includes the
                            delivery timeline, cost, scope of works & T&Cs for you to review, approve & electronically sign!
                        </p>
                    </div>
                    <img src={'Assets/landing-page/work-green.png'} className="img-fluid big" alt="work-image2"/>
                    </div>
                </div>
                <div className="item card">
                    <div className="item-inner-wrapper">
                    <div className="card-copy">
                        <p className="tag">Step 3.</p>
                        <h3 className="py-3">Project works commence.</h3>
                        <p>Once the project document has been signed by all parties & down payment is made, you will receive a project questionnaire to answer.
                            We then discuss your answers & start the works!
                        </p>
                    </div>
                    <img src={'Assets/landing-page/work-purple.png'} className="img-fluid big" alt="work-image3"/>
                    </div>
                </div>
                </OwlCarousel> 
            </div>
        </section>
        <section className="division-area background-white">
            <Container>
                <p className="tag">CREATIVITY</p>
                <Row>
                    <Col md={3} sm={12}></Col>
                    <Col md={9} sm={12}>
                        <div className="division-container">
                        <div className="division-child">
                            <p>01</p>
                            <h3>Brand Strategy</h3>
                        </div>
                        <div className="division-child">
                            <p>02</p>
                            <h3>Typography</h3>
                        </div>
                        <div className="division-child">
                            <p>03</p>
                            <h3>Motion Design</h3>
                        </div>
                        <div className="division-child">
                            <p>04</p>
                            <h3>Logo Design</h3>
                        </div>
                        <div className="division-child">
                            <p>05</p>
                            <h3>Graphic Design</h3>
                        </div>
                        <div className="division-child">
                            <p>06</p>
                            <h3>Design</h3>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="division-area background-smoke">
            <Container>
                <p className="tag">BUSINESS</p>
                <Row>
                    <Col md={3} sm={12}></Col>
                    <Col md={9} sm={12}>
                        <div className="division-container">
                        <div className="division-child">
                            <p>01</p>
                            <h3>Social Media</h3>
                        </div>
                        <div className="division-child">
                            <p>02</p>
                            <h3>Marketing</h3>
                        </div>
                        <div className="division-child">
                            <p>03</p>
                            <h3>Branding</h3>
                        </div>
                        <div className="division-child">
                            <p>04</p>
                            <h3>Lead Generation</h3>
                        </div>
                        <div className="division-child">
                            <p>05</p>
                            <h3>Content Creation</h3>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Container>
        </section>
        </div>
    );
}
const styles ={
    Content:{
        height:"100vh",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center"
    }
}
export default About;