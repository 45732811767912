export const workData = [
    {
        id: 1,
        client: "Gamuda Cove",
        sector: "Real Estate",
        discipline: "Brand Identity, Digital Design",
        name: "Are You SMART Campaign",
        about: "Introducing the 'Are You SMART Campaign,' an integral part of the Gamuda Cove Sustainable Initiative. Our role encompassed crafting the campaign's masthead, setting a compelling communication tone, and bringing the initiative to life through a captivating campaign video.",
        gallery: ['Assets/works/ARE-U-SMART/1.jpg', 'Assets/works/ARE-U-SMART/2.jpg'],
        videoGallery: "https://www.youtube.com/embed/Yo7s3LeAX48?si=NO1WNAn-CnS7b18R"
    },
    {
        id: 2,
        client: "Paramount Property",
        sector: "Real Estate",
        discipline: "Editorial & Prints",
        name: "Berkeley Uptown Residences",
        about: "Presenting the 'Berkeley Uptown Residences' project, a collaboration with Paramount Property. Our expertise was brought to life in the vibrant street bunting and a comprehensive set of impactful outdoor communication materials.",
        gallery: ['Assets/works/BERKELEY-UPTOWN/1.jpg', 'Assets/works/BERKELEY-UPTOWN/2.jpg']
    },
    {
        id: 3,
        client: "CPI Land Sdn Bhd",
        sector: "Real Estate",
        discipline: "Awards Submission",
        name: "DIAN II Residences: StarProperty Awards 2023 ",
        about: "Experience the DIAN II Residency StarProperty Awards 2023 winning deck, another esteemed project by CPI Land. Our role was pivotal in crafting the Award submission deck tailored for the exclusive StarProperty Awards 2023, under the coveted category of The Resort-Living Homes Awards.",
        gallery: [
            'Assets/works/CPI_DIAN11_AWARDS/1.jpg', 
            'Assets/works/CPI_DIAN11_AWARDS/2.jpg',
            'Assets/works/CPI_DIAN11_AWARDS/3.jpg',
            'Assets/works/CPI_DIAN11_AWARDS/4.jpg',
            'Assets/works/CPI_DIAN11_AWARDS/5.jpg',]
    },
    {
        id: 4,
        client: "CPI Land Sdn Bhd",
        sector: "Real Estate",
        discipline: "Awards Submission",
        name: "TUAN 2egacy Residency: StarProperty Awards 2023 ",
        about: "Introducing the Tuan Legacy Residency StarProperty Awards 2023 winning deck, a proud project by CPI Land. Our expertise shines through in crafting the meticulous Award submission deck, specifically for the prestigious StarProperty Awards 2023, under the esteemed category of The Foreign Touch Awards.",
        gallery: [
            'Assets/works/CPI_TUAN2_AWARDS/1.jpg', 
            'Assets/works/CPI_TUAN2_AWARDS/2.jpg',
            'Assets/works/CPI_TUAN2_AWARDS/3.jpg',
            'Assets/works/CPI_TUAN2_AWARDS/4.jpg',
            'Assets/works/CPI_TUAN2_AWARDS/5.jpg',
        ]
    },
    {
        id: 5,
        client: "Golden Land Berhad",
        sector: "Real Estate",
        discipline: "Brand Identity, Editorial & Prints",
        name: "D'Sini Residences",
        about: "Introducing 'D'Sini Residences' by Golden Land Berhad, a remarkable high-rise living destination nestled in the heart of Setia Alam. Our expertise extends across every aspect of this project, from crafting the distinctive project name, logo, and establishing a compelling marketing communication tone, to ensuring branding consistency and creating captivating key visuals. As a testament to our commitment, we've also designed a master brochure that beautifully showcases the essence and allure of 'D'Sini Residences.'",
        gallery: [
            'Assets/works/DSINI/1.jpg',
            'Assets/works/DSINI/2.jpg',
            'Assets/works/DSINI/3.jpg',
            'Assets/works/DSINI/4.jpg',
            'Assets/works/DSINI/5.jpg'
        ]
    },
    {
        id: 6,
        client: "Glomac Berhad",
        sector: "Real Estate",
        discipline: "Digital Design, Editorial & Prints",
        name: "Aidilfitri Pasti Riuh Raya Campaign",
        about: "Presenting 'Aidilfitri Pasti Riuh,' a vibrant Raya Campaign by Glomac Berhad. Our involvement spanned every aspect, from crafting the captivating campaign name to designing the eye-catching masthead. We brought the spirit of the season to life through a full set of marketing communication collateral for roadshows and outdoor displays. Additionally, our expertise extended to creating a compelling array of digital marketing materials.",
        gallery: [
            'Assets/works/GLOMAC_AIDILFITRI_PASTI_RIUH/1.jpg',
             'Assets/works/GLOMAC_AIDILFITRI_PASTI_RIUH/2.jpg',
             'Assets/works/GLOMAC_AIDILFITRI_PASTI_RIUH/3.jpg',
             'Assets/works/GLOMAC_AIDILFITRI_PASTI_RIUH/4.jpg',
             'Assets/works/GLOMAC_AIDILFITRI_PASTI_RIUH/5.jpg'
            ]
    },
    {
        id: 7,
        client: "Glomac Berhad",
        sector: "Real Estate",
        discipline: "Editorial & Prints",
        name: "Property Roadshows",
        about: "Explore our captivating roadshow collaterals for Glomac Berhad's diverse properties and events. Each piece reflects the event's spirit, from unique campaign names to eye-catching designs, creating an engaging experience for attendees.",
        gallery: ['Assets/works/GLOMAC_ROADSHOW/1.jpg', 'Assets/works/GLOMAC_ROADSHOW/2.jpg', 'Assets/works/GLOMAC_ROADSHOW/3.jpg']
    },
    {
        id: 8,
        client: "MARINERO",
        sector: "Food & Drinks",
        discipline: "Brand Identity",
        name: "Marinero Mediterranean Dining & Bar @ Lalaport",
        about: "Welcome to 'Marinero Mediterranean Dining & Bar,' a culinary haven nestled in Lalaport @ KL. Our creative journey extends beyond naming, logo, and marketing, helping create a seamless experience. The tone we set ensures interior harmony, a consistent atmosphere that enhances every moment.",
        gallery: [
            'Assets/works/MARINERO/1.jpg', 
            'Assets/works/MARINERO/2.jpg',
            'Assets/works/MARINERO/3.jpg',
            'Assets/works/MARINERO/4.jpg',
            'Assets/works/MARINERO/5.jpg'
        ]
    },
    {
        id: 9,
        client: "AVALAND Berhad",
        sector: "Real Estate",
        discipline: "Digital Design",
        name: "AETAS Damansara Billboard",
        about: "Presenting the AETAS Damansara project billboard working in collaboration with AVALAND Berhad. Our expertise was brought to life in the set of impactful outdoor communication materials.",
        gallery: ['Assets/works/MCT_AETAS/1.jpg', 'Assets/works/MCT_AETAS/2.jpg']
    },
    {
        id: 10,
        client: "AVALAND Berhad",
        sector: "Real Estate",
        discipline: "Digital Design",
        name: "EDM Marketing Campaigns",
        about: "Explore our engaging eDMs for AVALAND Berhad, designed to elevate diverse events. Our expertise shines from unique campaign names to captivating masthead designs, reflecting each event's essence. A curated digital collateral set amplifies AVALAND's impact and versatility.",
        gallery: [
            'Assets/works/MCT_EDM/1.jpg',
            'Assets/works/MCT_EDM/2.jpg',
            'Assets/works/MCT_EDM/3.jpg',
            'Assets/works/MCT_EDM/4.jpg',
            'Assets/works/MCT_EDM/5.jpg',
            'Assets/works/MCT_EDM/6.jpg']
    },
    {
        id: 11,
        client: "AVALAND Berhad",
        sector: "Real Estate",
        discipline: "Digital Design",
        name: "Quarterly Newsletter",
        about: "Discover the Quarterly Newsletter crafted for AVALAND Berhad. Dive into the latest updates and events from the group, ensuring readers and consumers are always in the loop with their exciting developments.",
        gallery: [
            'Assets/works/MCT_Newsletter/1.jpg',
            'Assets/works/MCT_Newsletter/2.jpg',
            'Assets/works/MCT_Newsletter/3.jpg',
            'Assets/works/MCT_Newsletter/4.jpg']
    },
    {
        id: 12,
        client: "Scientex Berhad",
        sector: "Real Estate",
        discipline: "Brand Identity, Digital Design, Editorial & Prints",
        name: "MORI 2 Residences",
        about: "Introducing 'MORI2 Residences,' the upcoming high-rise project by Scientex Berhad in Rawang. Our creative journey began with the logo and naming provided by the client. We've extended our expertise to crafting a captivating marketing communication tone, ensuring branding consistency, and designing a comprehensive set of collateral",
        gallery: [
            'Assets/works/MORI/1.jpg', 
            'Assets/works/MORI/2.jpg',
            'Assets/works/MORI/3.jpg',
            'Assets/works/MORI/4.jpg',
            'Assets/works/MORI/5.jpg',
            'Assets/works/MORI/6.jpg',]
    },
    {
        id: 13,
        client: "Pinnacle Homes Group of Companies",
        Sector: "Real Estate",
        discipline: "Brand Identity, Digital Design, Editorial & Prints",
        name: "Pinnacle Subang Jaya Residences",
        about: "Welcome to 'Pinnacle Subang Jaya Residences' by Pinnacle Homes, an upcoming high-rise gem in Subang Jaya SS16. Our creative journey began with the logo and naming provided by the client. Our scope extended to crafting the project's key visuals and identity, establishing a captivating marketing communication tone, ensuring branding consistency, and designing a comprehensive set of non-ground marketing collateral, spanning both digital and traditional platforms.",
        gallery: ['Assets/works/PSJ/1.jpg', 'Assets/works/PSJ/2.jpg', 'Assets/works/PSJ/3.jpg']
    },
    {
        id: 14,
        client: "Sunsuria Berhad",
        sector: "Real Estate",
        discipline: "Brand Identity, Editorial & Prints",
        name: "Corporate Identity",
        about: "Introducing the Sunsuria Master Group Branding Guideline: a comprehensive roadmap for Sunsuria's corporate identity and consistent collateral templates across all platforms. Dive into the brand hierarchy, discover the fonts that define the brand, and experience a unified visual identity that reflects Sunsuria's essence. This is how we align every touchpoint with the Sunsuria Master Group spirit, Malaysia's distinguished property developer.",
        gallery: [
            'Assets/works/SUNSURIA/1.jpg',
            'Assets/works/SUNSURIA/2.jpg',
            'Assets/works/SUNSURIA/3.jpg',
            'Assets/works/SUNSURIA/4.jpg',
            'Assets/works/SUNSURIA/5.jpg',
            'Assets/works/SUNSURIA/6.jpg',
            'Assets/works/SUNSURIA/7.jpg',
            'Assets/works/SUNSURIA/9.jpg',
            'Assets/works/SUNSURIA/10.jpg',
            'Assets/works/SUNSURIA/11.jpg']
    },
    {
        id: 15,
        client: "Sunsuria Berhad",
        sector: "Real Estate",
        discipline: "Awards Submission",
        name: "SENI Residences: StarProperty Awards 2023 ",
        about: "Presenting the award-winning submission deck for Seni Residences at theStarProperty Awards 2023, a project by Sunsuria Berhad. Our expertise shines in creating this meticulous deck, tailored for the prestigious Family-Friendly Awards category.",
        gallery: [
            'Assets/works/SUNSURIA_SENI-RECIDENCES_AWARDS/1.jpg', 
            'Assets/works/SUNSURIA_SENI-RECIDENCES_AWARDS/2.jpg',
            'Assets/works/SUNSURIA_SENI-RECIDENCES_AWARDS/3.jpg',
            'Assets/works/SUNSURIA_SENI-RECIDENCES_AWARDS/4.jpg',
            'Assets/works/SUNSURIA_SENI-RECIDENCES_AWARDS/5.jpg',]
    },
    {
        id: 16,
        client: "Mitraland Group",
        sector: "Real Estate",
        discipline: "Digital Design",
        name: "Trash-to-Treasure Digital Campaign",
        about: "Embrace the wisdom of 'one man's trash is another's treasure' through the Gravit8 Trash to Treasure Competition 2023, a captivating addition to Mitraland Group's Campaign. Our involvement spanned naming, masthead design, a diverse array of marketing communication materials for a range of digital campaigns, as well as an extensive set of digital marketing assets.",
        gallery: ['Assets/works/TRASH-TO-TREASURE/1.jpg', 'Assets/works/TRASH-TO-TREASURE/2.jpg']
    },
  ];