import React from "react";
import {useEffect, useLocation, useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Button} from 'react-bootstrap';
import { brands } from "./try";
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLongArrowRight} from "@fortawesome/free-solid-svg-icons";

const SingleWork = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
function findParentsId(brands, projectId) {
    for (let x = 0; x < brands.length; x++) {
      const {projects} = brands[x];
      const found = projects.find((project) => project.id === projectId);
      if (found) {
        const others = projects.filter((project) => project.id !== projectId);
        return others;
      }
    }
    return "not found";
}

function findNextBrand(brands, projectId){
    for (let x = 0; x < brands.length; x++){
      if(brands[x].projects.some((project) => project.id === projectId)){
        return (
          <Row>
            <Col sm={12} className="mb-5">
                <Button className="nextbrand-button">
                    <Link to="/work">Who We Are</Link>
                    <FontAwesomeIcon icon={faLongArrowRight} className="long-arrow"></FontAwesomeIcon>
                </Button>
            </Col>
            <Col md={6}>
                <h2>{brands[x+1].brand}</h2>
            </Col>
            <Col md={6}>
                <p>{brands[x+1].type}</p>
            </Col>
          </Row>
        );
      }
      else{
        return(
            <Row>
            <Col sm={12} className="mb-5">
                <Button className="nextbrand-button">
                    <Link to="/work">Who We Are</Link>
                    <FontAwesomeIcon icon={faLongArrowRight} className="long-arrow"></FontAwesomeIcon>
                </Button>
            </Col>
            <Col md={6}>
                <h2>{brands[0].brand}</h2>
            </Col>
            <Col md={6}>
                <p>{brands[0].type}</p>
            </Col>
          </Row>
        )
      }
    }
  }

    return (  
        <div className="single-work">
            <section>
                <Container>
                    <Row className="justify-content-center">
                    <Col md={6} className="pb-3">
                        <h2>{location.state.data.name}</h2>
                    </Col>
                    <Col md={6} className="pb-3">
                        <p>{location.state.data.about}</p>
                    </Col>
                    <Col sm={12} className="text-center">
                        <img src={location.state.data.thumbnail} className="img-fluid py-3 banner-full" alt="work-image1"/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <p>Brand</p>
                        <p>{location.state.data.name}</p>
                    </Col>
                    <Col md={4}>
                        <p>Project Type</p>
                        <p>{location.state.data.projectType}</p>
                    </Col>
                    <Col md={4}>
                        <p>Awards</p>
                        <p>{location.state.data.awards}</p>
                        <p>{location.state.data.awardsDetails}</p>
                    </Col>
                </Row>
                </Container>
            </section>
            <hr></hr>
            <section>
                <Container>
                    <Row>
                        <Col md={6}>
                            <p>Brief, Objectives & Challenges</p>
                            <h3>How to steal attention on media social?</h3>
                            <p>{location.state.data.contentCopy}</p>
                        </Col>
                        <Col md={6}>
                            <img src={location.state.data.before} className="img-fluid" alt="work-image1"/>
                            <img src={location.state.data.after} className="img-fluid" alt="work-image1"/>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="background-smoke">
                <Container>
                    <h1 className="text-center">Work</h1>
                    <Row>
                        {findParentsId(brands, location.state.data.index).map((project) => {
                            return(
                                <Col md={6} onClick={() => {navigate(`/client/${project.id}`,{replace: true})}}>
                                    <img src={project.imgthumbnail} className="clickable-img img-fluid py-3" alt="work-image1"/>
                                    <p>{project.name}</p>
                                </Col>
                            )
                        })}
                        <Col sm={12} className="text-center showmore mt-5">
                            <Button><Link to="/work">Load More</Link></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    {findNextBrand(brands, location.state.data.index)}
                </Container>
            </section>
        </div>
    );
}
 
export default SingleWork;