import React from "react";
import {useState, useRef, useEffect} from "react";
import { Navbar, Nav, Container} from 'react-bootstrap';
import { Link } from "react-router-dom";
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

const Header = () => {
    const [navBackground, setNavBackground] = useState(false)
    const navRef = useRef()
    navRef.current = navBackground
    useEffect(() => {
      const handleScroll = () => {
        const show = window.scrollY > 50
        if (navRef.current !== show) {
          setNavBackground(show)
        }
      }
      document.addEventListener('scroll', handleScroll)
      return () => {
        document.removeEventListener('scroll', handleScroll)
      }
    }, [])

    return (  
        <Navbar expand="lg" fixed="top" className="px-lg-0" style={{ transition: '0.5s ease', backgroundColor: navBackground ? 'rgb(85 182 183)' : 'transparent'}}>
             <Container>
                <Navbar.Brand>
                  <Link to="/home">
                    <img src={'Assets/logo/logo-quadric-black.png'} className="d-inline-block align-top logo"/>
                  </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Navbar.Toggle className="d-block d-lg-none" aria-controls="basic-navbar-nav"><FontAwesomeIcon icon={faXmark}></FontAwesomeIcon></Navbar.Toggle>
                  <Nav className="ml-auto">
                        <Link className="d-block d-lg-none" to="/packages">
                          <img src={'Assets/logo/logo-quadric-white.png'} className="logo nav-link"/>
                        </Link>
                      <Nav.Link className="mt-5 mt-lg-0">
                          <Link to="/about">Who We Are</Link>
                      </Nav.Link>
{/*                        <Nav.Link>
                          <Link to="/packages">Packages</Link>
                      </Nav.Link> 
                       <Nav.Link>
                          <Link to="/work">Work</Link>
                      </Nav.Link>  */}
                      <Nav.Link className="mr-0">
                          <Link to="/contact">Contact Us</Link>
                      </Nav.Link>
                      <Nav.Link>
                          <Link to="*"></Link>
                      </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
            </Container>
      </Navbar>
    );
}
 
export default Header;