import React, {useState} from "react";
import './Home.css';
import { Container, Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLongArrowRight, faXmark} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import WOW from 'wowjs';
import { useEffect } from "react";
import { workData } from "data";

const Home = () => {
    useEffect(() => {
        document.title = 'Home | Quadrics';
      });
    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

        // Initialize the state to keep track of the hover state for each image
        const [hoverStates, setHoverStates] = useState(new Array(workData.length).fill(false));

        // Function to handle image hover
        const handleImageHover = (index) => {
            const newHoverStates = [...hoverStates];
            newHoverStates[index] = true;
            setHoverStates(newHoverStates);
        };

        // Function to handle image leave
        const handleImageLeave = (index) => {
            const newHoverStates = [...hoverStates];
            newHoverStates[index] = false;
            setHoverStates(newHoverStates);
        };

      const [showMoreWorks, setShowMoreWorks] = useState(false);

      // Function to toggle the visibility of '.moreworks'
      const toggleMoreWorks = () => {
        setShowMoreWorks(!showMoreWorks);
      };
      const [work, setWork] = useState([]);
      useEffect(() => {
        setWork(workData);
      }, []);

      
    return ( 
        <div>
            <div className="home-banner">
                 <div className="d-block d-lg-none ">
                    <lottie-player src="https://lottie.host/d03b5d70-4e57-47a1-b6b7-a8728ef8331b/QkcMruJctW.json" background="transparent"  speed="1"  loop autoplay></lottie-player>
                </div>
                 <Container>
                    <Row className="justify-content-center">
                        <Col className="home-banner-copy" xl={6} lg={7}>
                            <h1 className="mb-0 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">We're more than just brand & design creators</h1>
                            <p className="mb-0 pt-3 pb-5 wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">
                            We're your progress partners, dedicated to propelling you forward, regardless of industry. Beyond aesthetics, we craft growth. Your journey is our masterpiece, unbound by industry limits.
                            </p>
                            <Link className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.5s" to="/about">Who We Are<FontAwesomeIcon icon={faLongArrowRight} className="long-arrow"></FontAwesomeIcon></Link>
                        </Col>
                        <Col className="d-none d-lg-block" xl={6} lg={5}>
                            <lottie-player src="https://lottie.host/f43d00c9-96b2-46d9-8163-899280480634/nVkRQJEt79.json" background="transparent"  speed="1"  loop autoplay></lottie-player>
                        </Col>
                    </Row>
                </Container>
                {/* <Container>
                    <Row className="justify-content-center">
                        <Col className="home-banner-copy w-100" xl={12} lg={12}>
                            <h1 className="mb-0 wow fadeInUp text-center" data-wow-duration="1s" data-wow-delay=".1s">Under Maintenance</h1>
                        </Col>
                    </Row>
                </Container> */}
            </div>
            <section className="work-area">
                <Container>
                    <h2 className="text-center pb-5">Works</h2>
                    <Row>
                        <Col md={6} className="medium-frame">
                            <Link
                            to={`/single/${workData[0].id}`}
                            >
                                <img
                                key={1}
                                src={hoverStates[1] ? workData[0].gallery[1] : workData[0].gallery[0]}
                                className="img-fluid big"
                                alt={`work-image0`}
                                onMouseEnter={() => handleImageHover(1)}
                                onMouseLeave={() => handleImageLeave(1)}
                                />
                            </Link>
                        </Col>
                        <Col md={3} className="medium-frame">
                            <Link
                            to={`/single/${workData[1].id}`}
                            >
                                <img
                                key={2}
                                src={hoverStates[2] ? workData[1].gallery[1] : workData[1].gallery[0]}
                                className="img-fluid small"
                                alt={`work-image0`}
                                onMouseEnter={() => handleImageHover(2)}
                                onMouseLeave={() => handleImageLeave(2)}
                                />
                            </Link>
                            <Link
                            to={`/single/${workData[2].id}`}
                            >
                                <img
                                key={3}
                                src={hoverStates[3] ? workData[2].gallery[1] : workData[2].gallery[0]}
                                className="img-fluid small"
                                alt={`work-image0`}
                                onMouseEnter={() => handleImageHover(3)}
                                onMouseLeave={() => handleImageLeave(3)}
                                />
                            </Link>
                        </Col>
                        <Col md={3} className="medium-frame">
                            <Link
                            to={`/single/${workData[3].id}`}
                            >
                                <img
                                key={4}
                                src={hoverStates[4] ? workData[3].gallery[1] : workData[3].gallery[0]}
                                className="img-fluid small"
                                alt={`work-image0`}
                                onMouseEnter={() => handleImageHover(4)}
                                onMouseLeave={() => handleImageLeave(4)}
                                />
                            </Link>
                            <Link
                            to={`/single/${workData[4].id}`}
                            >
                                <img
                                    key={5}
                                    src={hoverStates[5] ? workData[4].gallery[1] : workData[4].gallery[0]}
                                    className="img-fluid small"
                                    alt={`work-image0`}
                                    onMouseEnter={() => handleImageHover(5)}
                                    onMouseLeave={() => handleImageLeave(5)}
                                />
                            </Link>
                        </Col>
                        <Col md={4} className="big-frame">
                            <Link
                            to={`/single/${workData[5].id}`}
                            >
                                <img
                                    key={6}
                                    src={hoverStates[6] ? workData[5].gallery[1] : workData[5].gallery[0]}
                                    className="img-fluid small"
                                    alt={`work-image3`}
                                    onMouseEnter={() => handleImageHover(6)}
                                    onMouseLeave={() => handleImageLeave(6)}
                                 />
                            </Link>
                            <Link
                            to={`/single/${workData[6].id}`}
                            >
                                <img
                                    key={7}
                                    src={hoverStates[7] ? workData[6].gallery[1] : workData[6].gallery[0]}
                                    className="img-fluid small"
                                    alt={`work-image3`}
                                    onMouseEnter={() => handleImageHover(7)}
                                    onMouseLeave={() => handleImageLeave(7)}
                                />
                            </Link>
                            <Link
                            to={`/single/${workData[7].id}`}
                            >
                                <img
                                    key={8}
                                    src={hoverStates[8] ? workData[7].gallery[1] : workData[7].gallery[0]}
                                    className="img-fluid small"
                                    alt={`work-image3`}
                                    onMouseEnter={() => handleImageHover(8)}
                                    onMouseLeave={() => handleImageLeave(8)}
                                />
                            </Link>
                        </Col>
                        <Col md={8} className="big-frame">
                            <Link
                            to={`/single/${workData[8].id}`}
                            >
                                <img
                                    key={9}
                                    src={hoverStates[9] ? workData[8].gallery[0] : workData[8].gallery[0]}
                                    className="img-fluid big"
                                    alt={`work-image3`}
                                    onMouseEnter={() => handleImageHover(9)}
                                    onMouseLeave={() => handleImageLeave(9)}
                                />
                            </Link>
                        </Col>
                        <Col sm={12} className={`text-center showmore mt-3 ${showMoreWorks ? 'd-none' : ''}`}>
                            <Button id="morework-btn" onClick={toggleMoreWorks}>
                            More Works
                            </Button>
                        </Col>
                        {showMoreWorks && (
                            <Row className="mx-0 moreworks">
                                <Col md={4}>
                                    <Link
                                    to={`/single/${workData[9].id}`}
                                    >
                                        <img
                                            key={10}
                                            src={hoverStates[10] ? workData[9].gallery[1] : workData[9].gallery[0]}
                                            className="img-fluid"
                                            alt={`work-image3`}
                                            onMouseEnter={() => handleImageHover(10)}
                                            onMouseLeave={() => handleImageLeave(10)}
                                        />
                                    </Link>
                            </Col>
                            <Col md={4}>
                                    <Link
                                    to={`/single/${workData[10].id}`}
                                    >
                                        <img
                                            key={11}
                                            src={hoverStates[11] ? workData[10].gallery[1] : workData[10].gallery[0]}
                                            className="img-fluid"
                                            alt={`work-image3`}
                                            onMouseEnter={() => handleImageHover(11)}
                                            onMouseLeave={() => handleImageLeave(11)}
                                        />
                                    </Link>                               
                            </Col>
                            <Col md={4}>
                                <Link
                                    to={`/single/${workData[11].id}`}
                                    >
                                    <img
                                        key={12}
                                        src={hoverStates[12] ? workData[11].gallery[1] : workData[11].gallery[0]}
                                        className="img-fluid"
                                        alt={`work-image3`}
                                        onMouseEnter={() => handleImageHover(12)}
                                        onMouseLeave={() => handleImageLeave(12)}
                                    />
                                    </Link>    
                            </Col>
                            <Col md={6} className="medium-frame">
                                <Link
                                    to={`/single/${workData[12].id}`}
                                    >
                                    <img
                                        key={13}
                                        src={hoverStates[13] ? workData[12].gallery[1] : workData[12].gallery[0]}
                                        className="img-fluid big"
                                        alt={`work-image3`}
                                        onMouseEnter={() => handleImageHover(13)}
                                        onMouseLeave={() => handleImageLeave(13)}
                                    />
                                </Link>    
                            </Col>
                            <Col md={3} className="medium-frame">
                                <Link
                                    to={`/single/${workData[13].id}`}
                                    >
                                    <img
                                        key={14}
                                        src={hoverStates[14] ? workData[13].gallery[1] : workData[13].gallery[0]}
                                        className="img-fluid"
                                        alt={`work-image3`}
                                        onMouseEnter={() => handleImageHover(14)}
                                        onMouseLeave={() => handleImageLeave(14)}
                                    />
                                </Link>
                                <Link
                                    to={`/single/${workData[14].id}`}
                                    >
                                    <img
                                        key={15}
                                        src={hoverStates[15] ? workData[14].gallery[1] : workData[14].gallery[0]}
                                        className="img-fluid small"
                                        alt={`work-image3`}
                                        onMouseEnter={() => handleImageHover(15)}
                                        onMouseLeave={() => handleImageLeave(15)}
                                    />
                                </Link>
                            </Col>
                            <Col md={3} className="medium-frame">
                                <Link
                                    to={`/single/${workData[15].id}`}
                                    >
                                    <img
                                        key={16}
                                        src={hoverStates[16] ? workData[15].gallery[1] : workData[15].gallery[0]}
                                        className="img-fluid small"
                                        alt={`work-image3`}
                                        onMouseEnter={() => handleImageHover(16)}
                                        onMouseLeave={() => handleImageLeave(16)}
                                    />
                                </Link>
                            </Col>
                            </Row>
                        )}
                    </Row>
                </Container>
            </section>
             <section className="black-copy-area">
                <lottie-player src="https://lottie.host/3f60119d-079c-4c71-bbfe-6220ae33f835/pL8vlKJSyK.json" background="transparent"  speed="1"  loop autoplay></lottie-player>
                <Container>
                    <Row>
                        <Col lg={7}>
                            <h2 className="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">Every day, inspiration for change surrounds us.</h2>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col lg={5} className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="1s">
                            <p>Change is our canvas, both in the everyday and beyond. In the realm of creativity and branding, who leads? What sets us apart? Purposeful innovation.</p>
                            <p>Why us? We immerse ourselves in our customers' world. Their needs guide us, shaping it, one improvement at a time.</p>
                            <p className="mb-0">
                            Industries thrive by challenging norms. Our driving force? Curiosity – crafting a clear vision of what's to come.
                            </p>
                            
                        </Col>
                    </Row>
                </Container>
            </section> 
        </div>
     );
}
 
export default Home;