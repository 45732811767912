export const brands =[
    {
        "id": 100,
        "brand": "Digital Symphony",
        "type" : "Professional Services",
        "projects":[
            {
                "id":1,
                "name":"project 1",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Brand Identity",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            },
            {
                "id": 2,
                "name":"project 2",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Editorial & Prints",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            },
            {
                "id": 3,
                "name":"project 3",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Digital Design",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            }
        ]
    },
    {
        "id": 101,
        "brand":"Chiqui Don",
        "type": "Food & Drinks",
        "projects":[
            {
                "id": 4,
                "name":"project 4",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Brand Identity",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            },
            {
                "id": 5,
                "name":"project 5",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Editorial & Prints",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            },
            {
                "id": 6,
                "name":"project 6",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Digital Design",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            }
        ]
    },
    {
        "id": 102,
        "brand":"Industrial Company",
        "type": "Manufacturing & Industrials",
        "projects":[
            {
                "id":7,
                "name":"project 7",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Brand Identity",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            },
            {
                "id": 8,
                "name":"project 8",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Editorial & Prints",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            },
            {
                "id": 9,
                "name":"project 9",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Digital Design",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            }
        ]
    },
    {
        "id": 103,
        "brand":"Gamuda Land",
        "type": "Real Estate",
        "projects":[
            {
                "id": 10,
                "name":"project 10",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Brand Identity",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            },
            {
                "id": 11,
                "name":"project 11",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Editorial & Prints",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            },
            {
                "id": 12,
                "name":"project 12",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Digital Design",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            }
        ]
    },
    {
        "id": 104,
        "brand":"ZARA",
        "type": "Retail & Leisure",
        "projects":[
            {
                "id": 13,
                "name":"project 13",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Brand Identity",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            },
            {
                "id": 14,
                "name":"project 14",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Editorial & Prints",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            },
            {
                "id": 15,
                "name":"project 15",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Digital Design",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            }
        ]
    },
    {
        "id": 105,
        "brand":"Universiti Sains Malaysia",
        "type": "Education",
        "projects":[
            {
                "id": 16,
                "name":"project 16",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Brand Identity",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            },
            {
                "id": 17,
                "name":"project 17",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Editorial & Prints",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            },
            {
                "id": 18,
                "name":"project 18",
                "imgthumbnail": require("./Gallery/work-red.png"),
                "typeOfWork": "Digital Design",
                "brandDetails": "Strategy and brand id entity for the ambitious online art retailer",
                "headerImage": require("./Gallery/work-red.png"),
                "Awards": "MARKies Awards 2021",
                "AwardDetails": "Most Creative Audio (Bronze)",
                "Content": "While this competition is healthy, it often means that competitors tend to produce similiar posts, thus drowning in the ocean of posts that in social media. How do make Allianz unique so it stands out more among the crowd, without so much resorting to pay-to-win?",
                "beforeImage": require("./Gallery/work-red.png"),
                "afterImage": require("./Gallery/work-red.png"),
            }
        ]
    }

];