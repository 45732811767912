import React from "react";
import './Packages.css';
import { Container, Col, Row} from 'react-bootstrap';
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import { useState, useEffect } from "react";
import WOW from 'wowjs';

const Packages = () => {
    useEffect(() => {
        document.title = 'Packages | Quadrics';
      });
    const options = {
        margin: 30,
        responsiveClass: true,
        autoplay: false,
        navText: ['<i class="fa fa-arrow-left"></i>','<i class="fa fa-arrow-right"></i>'],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
                nav: true,
                autoHeight: true,
            },
            768: {
                items: 2,
                margin: 0,
                nav: true,
                autoHeight: false,
                
            },
            1280: {
                items: 3,
                margin: 0,
                nav: false,
            }
        },
      };
      useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])
      const [show, setShow] = useState(false)
      const [show2, setShow2] = useState(false)
    return ( 
        <div>
            <div className="white-background top-content">
                <Container>
                    <Row>
                        <Col>
                            <h2 className="pb-md-5 pb-3 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">Services</h2>
                            <OwlCarousel items={3} className="services-slider" {...options}>
                                <div className="card background-black">
                                    <h3 className="pb-3 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">Basic</h3>
                                    <div className="time wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                                        <p><b>10 hours per month</b></p>
                                        <p className="slanted">2.5 hours per week</p>
                                    </div>
                                    <div className="extras wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                                        <p>MYR240/hour for additional hours</p>
                                        <p>-</p>
                                        <p>Up to 3 Meeting Calls per month to review project load</p>
                                    </div>
                                    <div className="price text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                                        <h3><sup>MYR</sup> 3,300<sub> / Month</sub></h3>
                                    </div>
                                </div>
                                <div className="card background-aqua">
                                    <h3 className="pb-3 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">Deluxe</h3>
                                    <div className="time wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                                        <p><b>20 hours per month</b></p>
                                        <p className="slanted">5 hours per week</p>
                                    </div>
                                    <div className="extras wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                                        <p>MYR200/hour for additional hours</p>
                                        <p>-</p>
                                        <p>Up to 4 Meeting Calls per month to review project load</p>
                                    </div>
                                    <div className="price text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                                        <h3><sup>MYR</sup> 5,880<sub> / Month</sub></h3>
                                    </div>
                                </div>
                                <div className="card background-black">
                                    <h3 className="pb-3 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">Premium</h3>
                                    <div className="time wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                                        <p><b>40 hours per month</b></p>
                                        <p className="slanted">10 hours per week</p>
                                    </div>
                                    <div className="extras wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                                        <p>MYR180/hour for additional hours</p>
                                        <p>-</p>
                                        <p>Up to 6 Meeting Calls per month to review project load</p>
                                    </div>
                                    <div className="price text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                                        <h3><sup>MYR</sup> 9,800<sub> / Month</sub></h3>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </div>
            <section className="retainer-area">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl={6} lg={10}>
                            <h2 className="text-center wow fadeInDown" data-wow-duration="1s" data-wow-delay=".6s">Retainer Packages Include</h2>
                            <p className="text-center pt-3 pb-5 mb-0 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s"> If none of these designer retainer packages meets your needs, you may consider about
                                purchasing work on a needed basis instead.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={styles.FirstBlue} className="materials-area">
                <Container>
                    <Row>
                        <Col md={3} sm={12}>
                            <h5 className="tag text-center text-md-left">Print Materials</h5>
                        </Col>
                        <Col md={9} className="d-none d-md-block">
                            <div className="materials-content">
                                <h5>Business Cards</h5>
                                <h5>Letterheads & Envelopes</h5>
                                <h5>Corporate Folders</h5>
                                <h5>Greetings</h5>
                                <h5>Event Posters</h5>
                                <h5>Flyers</h5>
                                <h5>Brochures</h5>
                                <h5>Booklets</h5>
                                <h5>Direct Mailers</h5>
                                <h5>Banner & Buntings</h5>
                                <h5>Signages</h5>
                                <h5>Menus</h5>
                                <h5>Packaging Design</h5>
                                <h5>Vehicle Wrap</h5>
                                <h5>Stickers</h5>
                            </div>
                            <p className="small pt-5">* Retainer packages does NOT include additional costs i.e. Additional stock photography, printing productions,
                            photography, custom font license purchase.</p>
                            <p className="small">* Retainer packages does NOT include New Logo, Branding Design or re-design, Logo and Branding packages will be 
                            quoted and billed as a flat fee.</p>
                        </Col>
                        <Col sm={12} className="d-block d-md-none my-5">
                            <div className="materials-content">
                                <h5>Business Cards</h5>
                                <h5>Letterheads & Envelopes</h5>
                                <h5>Corporate Folders</h5>
                                <h5>Greetings</h5>
                                <h5>Event Posters</h5>
                                <h5>Flyers</h5>
                                <h5>Brochures</h5>
                                {
                                    show?
                                    <div>
                                        <h5>Booklets</h5>
                                        <h5>Direct Mailers</h5>
                                        <h5>Banner & Buntings</h5>
                                        <h5>Signages</h5>
                                        <h5>Menus</h5>
                                        <h5>Packaging Design</h5>
                                        <h5>Vehicle Wrap</h5>
                                        <h5>Stickers</h5>
                                    </div>
                                    :null
                                }
                            </div>
                        </Col>
                        <Col sm={12} className="d-block d-md-none text-center">
                            <button className="viewmore-btn" onClick={()=>setShow(!show)}>View More</button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={styles.SecondBlue} className="materials-area">
                <Container>
                    <Row>
                        <Col md={3} sm={12}>
                            <h5 className="tag tag text-center text-md-left">Digital Materials</h5>
                        </Col>
                        <Col md={9} className="d-none d-md-block">
                            <div className="materials-content">
                                <h5>Social Media graphics</h5>
                                <h5>Blog Post Images</h5>
                                <h5>Web Banners</h5>
                                <h5>Digital Ads</h5>
                                <h5>Email Templates Designs</h5>
                                <h5>Digital Emailers (eDM) Designs</h5>
                                <h5>Website Graphics</h5>
                            </div>
                            <p className="small pt-5">* Retainer packages does NOT include Full Website Design or re-design. If you need a New Website designed
                            from scratch, this will be quoted and billed as flat fee.</p>
                            <p className="small">* Retainer packages does NOT include web hosting purchases</p>
                        </Col>
                        <Col sm={12} className="d-block d-md-none my-5">
                            <div className="materials-content">
                                <h5>Social Media graphics</h5>
                                <h5>Blog Post Images</h5>
                                <h5>Web Banners</h5>
                                {
                                    show2?
                                    <div>
                                        <h5>Digital Ads</h5>
                                        <h5>Email Templates Designs</h5>
                                        <h5>Digital Emailers (eDM) Designs</h5>
                                        <h5>Website Graphics</h5>
                                    </div>
                                    :null
                                }
                            </div>
                        </Col>
                        <Col sm={12} className="d-block d-md-none text-center">
                            <button className="viewmore-btn" onClick={()=>setShow2(!show2)}>View More</button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={styles.ThirdBlue} className="materials-area">
                <Container>
                    <Row>
                        <Col md={3} sm={12}>
                            <h5 className="tag tag text-center text-md-left">Ad Design</h5>
                        </Col>
                        <Col md={9} className="d-none d-md-block">
                            <div className="materials-content">
                                <h5>Print Ads</h5>
                                <h5>Digital Ads</h5>
                                <h5>Social Media Ads</h5>
                            </div>
                            <p className="small pt-5">* Retainer Packages does NOT include Social Media Management, Posting, etc. Social Media Package can be
                            quoted seperately.</p>
                        </Col>
                        <Col sm={12} className="d-block d-md-none mt-5">
                            <div className="materials-content">
                                <h5>Print Ads</h5>
                                <h5>Digital Ads</h5>
                                <h5>Social Media Ads</h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
     );
}
const styles ={
    FirstBlue:{
        backgroundColor: "#dcffff"
    },
    SecondBlue:{
        backgroundColor: "#b7e8e8"
    },
    ThirdBlue:{
        backgroundColor: "#61c5c5"
    }
}
export default Packages;