import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const MainLayout = (props) => {
    return(
        <>
            <Header></Header>
            <div>
                {props.children}
            </div>
            <Footer></Footer>
    </>
    );
}
 
export default MainLayout;