import React from "react";
import './Work.css';
import './Clients'
import { Container} from 'react-bootstrap';
import Clients from "./Clients";
const Work = () => {
    return (  
        <div>
            <section className="works-section">
                <Container>
                    <h2>Works</h2>
                    <div className="tab-content">
                        <Clients></Clients>
                    </div>
                </Container>
            </section>
        </div>
    );
}
 
export default Work;