import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import MainLayout from './layouts/main';
import Home from './components/Home/Home.jsx';
import About from './components/About/About.jsx';
import Packages from './components/Packages/Packages.jsx';
import Contact from './components/Contact/Contact.jsx';
import Thankyou from './components/Thankyou/Thankyou';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
      <App/>
    </BrowserRouter> 
{/*     <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<MainLayout><Home /></MainLayout>} />
        <Route path="/about" element={<MainLayout><About /></MainLayout>} />
        <Route path="/packages" element={<MainLayout><Packages /></MainLayout>} />
        <Route path="/contact" element={<MainLayout><Contact /></MainLayout>} />
        <Route path="/thankyou" element={ <MainLayout><Thankyou/></MainLayout>}/>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
