import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
    return (  
        <footer>
            <Container>
                <Row className="mobile-reverse">
                    <Col lg={6} md={12} className="text-center text-lg-left mt-4 mt-lg-0">
                        <Link to="/">
                            <img src={'Assets/logo/logo-quadric-white.png'} className="d-inline-block align-top logo-main"/>
                        </Link>
                    </Col>
                    <Col lg={6} md={12} className="border-bottom-mobile">
                        <ul className="text-center text-lg-left my-4 my-lg-0">
                            <li><Link to="/about">Who We Are</Link></li>
{/*                             <li className="my-3 my-lg-0"><Link to="/packages">Packages</Link></li>
                            <li><Link to="/work">Work</Link></li> */}
                            <li> <Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </Col>
                </Row>
                <Row className="py-0 py-lg-5">
                    <Col lg={3} className="my-4 my-lg-0">
                        <Link to="/" className="d-flex align-items-center linkedin-link justify-content-center justify-content-lg-start">
                            Follow us on <img src={'Assets/logo/logo-linkedin.png'} className="logo-extra ml-1"/>
                        </Link>
                    </Col>
                    <Col className="text-center text-lg-right">
                        <p class="mb-0 text-light">© Copyright {new Date().getFullYear()} Quadrics.</p>
                    </Col>
                </Row>
                <div>

                </div>
            </Container>
        </footer>
    );
}
 
export default Footer;