import React from "react";
import './Work.css';
import {Col} from 'react-bootstrap';
import {useNavigate } from "react-router-dom";
const ClientAll = (props) => {
    const navigate = useNavigate();
    return (  
        <Col md={4} className="pb-3">
            {<div onClick={() => {navigate(`/client/${props.index}`, {
                state: {
                    data: props
                }
            })}}>
            <img src={props.thumbnail} className="clickable-img img-fluid py-3" alt="work-image1"/>
            <p>{props.name}</p>
            </div>}
        </Col>
    );
}
 
export default ClientAll;