import React, { useEffect, useState } from "react";
import './Single.css';
import Fancybox from "./Fancybox";
import {useLocation, useNavigate, Link, useParams} from "react-router-dom";
import { Container, Row, Col, Button} from 'react-bootstrap';
import { workData } from "data";
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLongArrowRight, faXmark} from "@fortawesome/free-solid-svg-icons";


const Single = () => {    
    const { id } = useParams();
    const workId = parseInt(id);
    const [work, setWork] = useState(null);
    useEffect(() => {
        const fetchedWork = workData.find((work) => work.id === workId);
        setWork(fetchedWork);
      }, [workId]);
    
    const nextWorkId = workId;
    const nextWork = workData[nextWorkId];

    useEffect(() => {
        if (work) {
            document.title = work.name + ' | Quadrics';
        }
    }, [work]);

    return (  
        <div className="single-work">
            {work? (
                <div>
                    <section>
                        <Container>
                            <Row className="justify-content-center mt-5 pt-5">
                                <Col sm={12} className="px-0">
                                    <img src={work.gallery[0]} className="w-100" alt="work-image1"/>
                                </Col>
                                <Row className="mt-5">
                                    <Col md={6}>
                                        <h2>{work.name}</h2>
                                        <p className="mb-0 text-gray">{work.client}</p>
                                        <p className="mb-2 mb-md-0 text-gray">{work.sector}, {work.discipline}</p>
                                    </Col>
                                    <Col md={6}>
                                        <h3>{work.about}</h3>
                                    </Col>
                                </Row>
                            </Row>
                        </Container>
                    </section>
                    <section className="background-smoke">
                        <Container>
                            <h1 className="text-center mb-5">Gallery</h1>
                            <Row className="justify-content-center single-gallery">
                                {work.gallery.map((item) =>(
                                    <Col md={6}>
                                        <Fancybox
                                            options={{
                                            Carousel: {
                                                infinite: false,
                                            },
                                            }}
                                        >
                                            <a data-fancybox={work.name} href={item}>
                                                <img src={item} className="w-100" alt={work.name}/>
                                            </a>
                                        </Fancybox>
                                    </Col>
                                ))}
                                {work.videoGallery? (
                                <Col sm={12} className="mt-5">
                                    <iframe className="w-100" src={work.videoGallery} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </Col>
                               ): (<></>)}
                            </Row>
                        </Container>
                    </section>
                    {nextWork? (
                    <section>
                        <Container>
                            <Row>
                                <Col sm={12} className="mb-5">
                                    <Link to={`/single/${nextWork.id}`} className="nextbrand-button">
                                        Next Work
                                        <FontAwesomeIcon icon={faLongArrowRight} className="long-arrow"></FontAwesomeIcon>
                                    </Link>
                                </Col>
                                <Col md={6}>
                                    <h2>{nextWork.name}</h2>
                                </Col>
                                <Col md={6}>
                                    <p>{nextWork.about}</p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    ): (<></>)}
                </div>
            ): (<p>not found</p>)}
        </div>
    );
}
 
export default Single;