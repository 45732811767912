import {React} from "react";
import './Work.css';
import ClientAll from './ClientAll';
import ClientType from './ClientType';
import { Row, Col, Tabs, Tab} from 'react-bootstrap';
import { brands } from "./try";

const Clients = () => {
    return (  
        <>
        <Tabs defaultActiveKey="clientType" id="uncontrolled-tab-example" className="mb-3 justify-content-center">
            <Tab eventKey="clientType" title="Type of Client" className="clientType">
                <Row>
                    <Col md={4} className="py-3">
                        <h5>Real Estate</h5>
                        <img src={'Assets/landing-page/work-red.png'} className="img-fluid py-3" alt="work-image1"/>
                    </Col>
                    <Col md={4} className="py-3">
                        <h5>Food & Drinks</h5>
                        <img src={'Assets/landing-page/work-red.png'} className="img-fluid py-3" alt="work-image1"/>
                    </Col>                                
                    <Col md={4} className="py-3">
                        <h5>Manufacturing & Industrials</h5>
                        <img src={'Assets/landing-page/work-red.png'} className="img-fluid py-3" alt="work-image1"/>
                    </Col>                                
                    <Col md={4} className="py-3">
                        <h5>Professional Services</h5>
                        <img src={'Assets/landing-page/work-red.png'} className="img-fluid py-3" alt="work-image1"/>
                    </Col>                                
                    <Col md={4} className="py-3">
                        <h5>Retail & Leisure</h5>
                        <img src={'Assets/landing-page/work-red.png'} className="img-fluid py-3" alt="work-image1"/>
                    </Col>                                
                    <Col md={4} className="py-3">
                        <h5>Education</h5>
                        <img src={'Assets/landing-page/work-red.png'} className="img-fluid py-3" alt="work-image1"/>
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="workType" title="Type of Work">
                <div className="worktype">
                    <h5 className="pt-3">Brand Identity</h5>
                    <Row>
                    {brands.map((item) =>
                        item.projects.filter((sub) => sub.typeOfWork === 'Brand Identity').map(filteredTypeOfWork => (
                        <ClientType
                            key={filteredTypeOfWork.id}
                            index={filteredTypeOfWork.id}
                            name = {filteredTypeOfWork.name}
                            thumbnail = {filteredTypeOfWork.imgthumbnail}
                            about ={filteredTypeOfWork.brandDetails}
                            headerImg ={filteredTypeOfWork.headerImage}
                            projectType={filteredTypeOfWork.typeOfWork}
                            awards ={filteredTypeOfWork.Awards}
                            awardsDetails={filteredTypeOfWork.AwardDetails}
                            contentCopy={filteredTypeOfWork.Content}
                            before={filteredTypeOfWork.beforeImage}
                            after={filteredTypeOfWork.afterImage}
                        ></ClientType>
                        )))}
                    </Row>
                </div>
                <div className="worktype">
                    <h5>Editorial & Prints</h5>
                    <Row>
                    {brands.map((item) =>
                        item.projects.filter((sub) => sub.typeOfWork === 'Editorial & Prints').map(filteredTypeOfWork => (
                        <ClientType
                            key={filteredTypeOfWork.id}
                            index={filteredTypeOfWork.id}
                            name = {filteredTypeOfWork.name}
                            thumbnail = {filteredTypeOfWork.imgthumbnail}
                            about ={filteredTypeOfWork.brandDetails}
                            headerImg ={filteredTypeOfWork.headerImage}
                            projectType={filteredTypeOfWork.typeOfWork}
                            awards ={filteredTypeOfWork.Awards}
                            awardsDetails={filteredTypeOfWork.AwardDetails}
                            contentCopy={filteredTypeOfWork.Content}
                            before={filteredTypeOfWork.beforeImage}
                            after={filteredTypeOfWork.afterImage}
                        ></ClientType>
                        )))}
                    </Row>
                </div>
                <div className="worktype">
                    <h5>Digital Design</h5>
                    <Row>
                    {brands.map((item) =>
                        item.projects.filter((sub) => sub.typeOfWork === 'Digital Design').map(filteredTypeOfWork => (
                        <ClientType
                            key={filteredTypeOfWork.id}
                            index={filteredTypeOfWork.id}
                            name = {filteredTypeOfWork.name}
                            thumbnail = {filteredTypeOfWork.imgthumbnail}
                            about ={filteredTypeOfWork.brandDetails}
                            headerImg ={filteredTypeOfWork.headerImage}
                            projectType={filteredTypeOfWork.typeOfWork}
                            awards ={filteredTypeOfWork.Awards}
                            awardsDetails={filteredTypeOfWork.AwardDetails}
                            contentCopy={filteredTypeOfWork.Content}
                            before={filteredTypeOfWork.beforeImage}
                            after={filteredTypeOfWork.afterImage}
                        ></ClientType>
                        )))}
                    </Row>
                </div>
            </Tab>
            <Tab eventKey="allProjects" title="All Projects">
                <h5 className="pt-3">All Projects</h5>
                <>
                    {brands.map((item) =>
                        <Row>
                            {item.projects.map((sub) =>
                                <ClientAll
                                    key={sub.id}
                                    index={sub.id}
                                    name = {sub.name}
                                    thumbnail = {sub.imgthumbnail}
                                    about ={sub.brandDetails}
                                    headerImg ={sub.headerImage}
                                    projectType={sub.typeOfWork}
                                    awards ={sub.Awards}
                                    awardsDetails={sub.AwardDetails}
                                    contentCopy={sub.Content}
                                    before={sub.beforeImage}
                                    after={sub.afterImage}
                                ></ClientAll>   
                            )}
                        </Row>)}
                </>
            </Tab>
        </Tabs>
        </>
    );
}
 
export default Clients;